import { useTranslation } from '@pancakeswap/localization';
import { Button, Flex, Text } from '@pancakeswap/uikit';
import Container from 'components/Layout/Container';
import PageFullWidth from 'components/Layout/PageFullWidth';
import NextLink from 'next/link';
import styled from 'styled-components';

const ComingSoon = () => {
  const { t } = useTranslation()

  return (
    <PageFullWidth>
        <CsContainer>
            <Flex width="100%" height="auto" flexDirection='column'>
                <Flex width="100%" height="auto" justifyContent='center'>
                  <Images
                      src="/images/hanagold/comingsoon.png?version=1.0"
                      alt='comingsoon images'
                  />
                </Flex>
            </Flex>
        </CsContainer>
    </PageFullWidth>
  )
}

export default ComingSoon

const CsContainer = styled(Container)`
    width: 100%;
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background:url('/images/hanagold/pagenotfound.png?version=1.1');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    @media screen and (max-width:600px) {
        height: 75vh;
    }
`

const Images = styled.img`
    width: auto;
    height: 298px;
    @media screen and (max-width:600px) {
        width:100%;
        height: auto;
    }
`