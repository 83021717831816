import { SUPPORT_ONLY_ONUS } from 'config/constants/supportChains'
import ComingSoon from 'views/ComingSoon'
import Home from 'views/Home'

const IndexPage = () => {
  return <ComingSoon />
}
IndexPage.chains = SUPPORT_ONLY_ONUS

export default IndexPage
